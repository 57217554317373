import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function NewryReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Newry Developers</h4>

            <p className="mb-0">
                <p className="AboutExpoPara">Newry Properties ventured into real estate by creating a series of residential spaces at strategic locations in Chennai.</p>

            </p>

            {isShowMore && (

                <p className="mb-0">
                    <h6>Over 26 years of experience</h6>
                    <p className="AboutExpoPara">Newry Properties delivered nothing short of a masterstroke in every project and we have built a new benchmark in delivering quality homes. It all started with a realistic appraisal of your living needs.</p>

                    <h6>Our goal then and now is to provide quality Homes</h6>
                    <p className="AboutExpoPara">As a process-evolving company, we rethink and rebuild processes for the new age by combining the aesthetic and convenience of design thinking with scale and accuracy.</p>

                    <h6>Our Vision</h6>
                    <p className="AboutExpoPara">To Imprint a smile on every Newry homeowner’s face</p>

                    <h6>Our Mission</h6>
                    <p className="AboutExpoPara">To create aesthetically designed homes with a focus on quality and customer satisfaction.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default NewryReadMore;